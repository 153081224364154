<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

	<div>
        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">
            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                <h5>Coming Soon</h5>
            </a-col>
        </a-row>

	</div>

</template>

<script>
	
    
	export default {
		components: {
		},
		data() {
			return {
                
			
			}
		},
		created() {
		},
		methods: {

        }

		
	}

</script>

<style lang="scss">

	
</style>